import type { MsgTransfer } from 'cosmjs-types/ibc/applications/transfer/v1/tx'
import { Currency } from '@keplr-wallet/types'
import { SUPPORTED_COIN_DENOMS } from '@/config'
import { TX_MSG } from './registry'
import {
  Account,
  AccountCurrency,
  SelectedCoinDenom,
  SelectedStakedCoinDenom,
  SelectedCoinMinimalDenom,
  IBCMetaData,
  IBCDexMetaData,
  RedemptionMetaData,
  LsmLiquidStakeMetaData,
  LiquidStakeMetaData,
  IBCWithdrawStTokenMetaData,
  IBCLiquidStakeAutopilotMetaData,
  SafeModeAccount,
  MsgLsmLiquidStake,
  TiaRedemptionMetaData,
  TiaLiquidStakeMetaData,
  DymRedemptionMetaData,
  DymLiquidStakeMetaData
} from './types'
import { MsgLiquidStakeSDKType as MsgLiquidStake } from 'stridejs/types/codegen/stride/stakeibc/tx'
import { MsgLiquidStakeSDKType as TiaMsgLiquidStake } from 'stridejs/types/codegen/stride/staketia/tx'
import { MsgLiquidStakeSDKType as DymMsgLiquidStake } from 'stridejs/types/codegen/stride/stakedym/tx'

const SELECTED_COIN_DENOMS = [
  'ATOM',
  'SCRT',
  'STRD',
  'JUNO',
  'OSMO',
  'STARS',
  'LUNA',
  'EVMOS',
  'INJ',
  'UMEE',
  'CRE',
  'CMDX',
  'IBCX',
  'KUJI',
  'USK',
  'SWTH',
  'SOMM',
  'ISK',
  'USC',
  'BLD',
  'DYDX',
  'TIA',
  'DYM',
  'SAGA',
  'ISLM',
  'BAND'
]

// This sort of counts as utility more than a type-guard. That said, this is useful
// if we want to logically make sure that only supported denoms are used.
const isSupportedSelectedCoinDenom = (str: any): str is SelectedCoinDenom => {
  return SUPPORTED_COIN_DENOMS.includes(str)
}

const isSelectedCoinDenom = (str: any): str is SelectedCoinDenom => {
  return SELECTED_COIN_DENOMS.includes(str)
}

const SELECTED_COIN_MINIMAL_DENOMS = [
  'uatom',
  'uscrt',
  'ustrd',
  'ujuno',
  'uosmo',
  'ustars',
  'uluna',
  'aevmos',
  'inj',
  'uumee',
  'ucre',
  'ucmdx',
  'uibcx',
  'ukuji',
  'swth',
  'usomm',
  'ubld',
  'adydx',
  'utia',
  'adym',
  'usaga',
  'aISLM',
  'uband'
]

const isSelectedCoinMinimalDenom = (str: string): str is SelectedCoinMinimalDenom => {
  return SELECTED_COIN_MINIMAL_DENOMS.includes(str)
}

const STAKED_SELECTION_COIN_DENOMS = [
  'stATOM',
  'stSCRT',
  'stSTRD',
  'stJUNO',
  'stOSMO',
  'stSTARS',
  'stLUNA',
  'stEVMOS',
  'stINJ',
  'stUMEE',
  'stCRE',
  'stCMDX',
  'stIBCX',
  'stKUJI',
  'stSWTH',
  'stDYDX',
  'stTIA',
  'stDYM',
  'stSAGA',
  'stISLM',
  'stBAND'
]

const isStakedSelectedCoinDenom = (str: string): str is SelectedStakedCoinDenom => {
  return STAKED_SELECTION_COIN_DENOMS.includes(str)
}

// We're intentionally checking if partial is false or non-existent rather than being thorough.
// We want to be able to type-narrow a SafeModeAccount as Account whenever we need to.
const isAccount = (account: any): account is Account => {
  return account && !account.partial
}

// Unlike `isAccount`, we'll use this normally to identify than an account is in fact loaded in safe mode.
// We don't need to type-narrow an account into safe mode.
// Once we start reworking account balances, which will likely have separate queries and hooks for
// account balances and stuff, we can likely safely (no pun intended) remove "safeMode" by then.
// @TODO https://github.com/Stride-Labs/interface/issues/251
const isSafeModeAccount = (account: any): account is SafeModeAccount => {
  return account && 'partial' in account
}

const isAccountCurrency = (currency: Currency): currency is AccountCurrency => {
  return isSelectedCoinDenom(currency.coinDenom) && isSelectedCoinMinimalDenom(currency.coinMinimalDenom)
}

const isMsgTransfer = (message: any): message is MsgTransfer => {
  return message?.['@type'] === TX_MSG.MsgTransfer
}

const isMsgLiquidStake = (message: any): message is MsgLiquidStake => {
  return message?.['@type'] === TX_MSG.MsgLiquidStake
}

const isTiaMsgLiquidStake = (message: any): message is TiaMsgLiquidStake => {
  return message?.['@type'] === TX_MSG.StakeTiaMsgLiquidStake
}

const isDymMsgLiquidStake = (message: any): message is DymMsgLiquidStake => {
  return message?.['@type'] === TX_MSG.StakeDymMsgLiquidStake
}

const isMsgLsmLiquidStake = (message: any): message is MsgLsmLiquidStake => {
  return message?.['@type'] === TX_MSG.MsgLSMLiquidStake
}

const isIBCMetaData = (meta: any): meta is IBCMetaData => {
  return ['deposit', 'withdraw'].includes(meta.type)
}

const isIBCDexMetaData = (meta: any): meta is IBCDexMetaData => {
  return meta.type === 'ibc-dex'
}

const isIBCWithdrawStTokenMetaData = (meta: any): meta is IBCWithdrawStTokenMetaData => {
  return meta.type === 'ibc-withdraw-st-token'
}

const isRedemptionMetaData = (meta: any): meta is RedemptionMetaData => {
  return meta.type === 'unstaking'
}

const isTiaRedemptionMetaData = (meta: any): meta is TiaRedemptionMetaData => {
  return meta.type === 'tia-unstaking'
}

const isDymRedemptionMetaData = (meta: any): meta is DymRedemptionMetaData => {
  return meta.type === 'dym-unstaking'
}

const isLiquidStakeMetaData = (meta: any): meta is LiquidStakeMetaData => {
  return meta.type === 'stake'
}

const isTiaLiquidStakeMetaData = (meta: any): meta is TiaLiquidStakeMetaData => {
  return meta.type === 'tia-liquid-stake'
}

const isDymLiquidStakeMetaData = (meta: any): meta is DymLiquidStakeMetaData => {
  return meta.type === 'dym-liquid-stake'
}

const isLsmLiquidStakeMetaData = (meta: any): meta is LsmLiquidStakeMetaData => {
  return meta.type === 'lsm-liquid-stake'
}

const isIBCLiquidStakeAutopilotMetaData = (meta: any): meta is IBCLiquidStakeAutopilotMetaData => {
  return meta.type === 'ibc-liquid-stake-autopilot'
}

export {
  isAccount,
  isSafeModeAccount,
  isSupportedSelectedCoinDenom,
  isSelectedCoinDenom,
  isSelectedCoinMinimalDenom,
  isStakedSelectedCoinDenom,
  isAccountCurrency,
  isMsgTransfer,
  isMsgLiquidStake,
  isTiaMsgLiquidStake,
  isDymMsgLiquidStake,
  isMsgLsmLiquidStake,
  isIBCMetaData,
  isIBCDexMetaData,
  isIBCWithdrawStTokenMetaData,
  isIBCLiquidStakeAutopilotMetaData,
  isRedemptionMetaData,
  isTiaRedemptionMetaData,
  isDymRedemptionMetaData,
  isLsmLiquidStakeMetaData,
  isLiquidStakeMetaData,
  isTiaLiquidStakeMetaData,
  isDymLiquidStakeMetaData
}
